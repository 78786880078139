import waitForJQuery from "./waitForJQuery";

function addPolyfill(){
  $(".button").not(".js--withicon").has("svg").addClass("js--withicon");
  $(".m_meetings-meetings.m_meetings-meetings--show .extra__date-container")
    .not(".js-has-svg")
    .has("svg")
    .addClass("js-has-svg")
  $(".o-results.o-results--show .section")
    .not(".js-with-progress-level")
    .has(".progress-level")
    .addClass("js-with-progress-level");

  $(".m_blogs-posts a.small.hollow")
    .not(".js-with-back")
    .has(".icon--chevron-left")
    .addClass("js-with-back");

  $(".card a")
    .not(".js-link-with-image")
    .has(".card__image img, .card__image-top img")
    .addClass("js-link-with-image");

  $(".card a")
    .not(".js-link-image")
    .has("img.card__image, img.card__image-top")
    .addClass("js-link-image");

  $(
    ".o-participatory_process_groups.o-participatory_process_groups--show .section .row .column.text-muted"
  )
    .not(".js-with-icon-grid-three-up")
    .has(".icon--grid-three-up")
    .addClass("js-with-icon-grid-three-up");

}
/**
 * We sometimes uses the css :has() selector,
 * that have poor browser support.
 * This script will add a .js-has-<selector> to polyfill
 * this for older navigator.
 */
waitForJQuery().then(function () {
  addPolyfill()
  $(document).on("ajax:success", addPolyfill) 
});
